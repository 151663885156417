:root {
  --color: #fff;
  --accent: #7abaff;
  --primary: #2e343b;
  --secondary: #5f6d7a;
  --background: #24292e;
  --color-on-accent: #24292e;
}

img {
  filter: brightness(80%);
}

input:hover,
select:hover,
button:hover,
button:focus,
textarea:hover {
  opacity: 0.8;
}
